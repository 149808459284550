import PropTypes from "prop-types"

import { spacing } from "@ninjaone/tokens"
import { AlertMessage } from "@ninjaone/components"

import { Box } from "js/includes/components/Styled"
import { localizedWith } from "js/includes/common/utils"

import { BoldText } from "./textUtils"

export const ExpirationAlertMessage = ({ count, titleToken, singularText, pluralText }) => {
  return (
    <Box marginBottom={spacing[4]}>
      <AlertMessage variant="danger" titleToken={titleToken}>
        {localizedWith("You have <%count> <%>, please either renew or delete.", {
          count: () => <BoldText>{count === 1 ? singularText : pluralText}</BoldText>,
        })}
      </AlertMessage>
    </Box>
  )
}

ExpirationAlertMessage.propTypes = {
  count: PropTypes.number.isRequired,
  /** E.g.: "ADE profile expired" */
  titleToken: PropTypes.string.isRequired,
  /** E.g.: "1 expired ADE profile" - will result in "You have 1 expired ADE profile..." */
  singularText: PropTypes.string.isRequired,
  /** E.g.: "10 expired ADE profiles" - will result in "You have 10 expired ADE profiles..." */
  pluralText: PropTypes.string.isRequired,
}
