import { isFeatureEnabled, reportErrorAndShowMessage, localizationKey } from "js/includes/common/utils"
import { getQuickBooksIntegration } from "js/includes/common/client"

export const getQuickBooksIntegrationActionPayload = data => ({
  type: "NINJA_PSA_SET_QUICK_BOOKS_CONFIGURATION",
  payload: {
    ...data,
    enabled: data.status === "ACTIVE",
  },
})

export const requestQuickBooksIntegrationSettings = () => async dispatch => {
  if (!isFeatureEnabled("ninja_psa")) return

  try {
    const integrationData = await getQuickBooksIntegration()
    dispatch(getQuickBooksIntegrationActionPayload(integrationData))
  } catch (error) {
    reportErrorAndShowMessage(error, localizationKey("Error while fetching QuickBooks settings"))
  }
}
