import { useRef, useState } from "react"

import { Button, FileAttachment, Heading, Link, Text } from "@ninjaone/components"
import { ArrowUpToLineIcon, DownloadIcon, ExternalLinkIconLight } from "@ninjaone/icons"
import { spacing, typography } from "@ninjaone/tokens"
import { useMountedState } from "@ninjaone/utils"
import { isNotEmpty, localizationKey, localized, reportErrorAndShowMessage } from "js/includes/common/utils"
import { Box, Flex } from "js/includes/components/Styled"
import { HiddenInput } from "js/includes/components/Styled/Form"

export const ExternalLink = ({ labelText, href }) => {
  return (
    <Link {...{ href }} target="_blank" rel="noopener noreferrer" fontWeight={typography.fontWeight.medium}>
      {labelText} <ExternalLinkIconLight size="xs" />
    </Link>
  )
}

export const ConfigStep = ({ stepNumber, labelText, wrapperProps, children, required }) => {
  return (
    <Box {...wrapperProps}>
      {stepNumber && (
        <Heading level={3} color="colorTextStrong">
          {localized("Step {{stepNumber}}", { stepNumber })}
        </Heading>
      )}
      {labelText && (
        <Box margin={[spacing[1], 0, spacing[2]]}>
          <Text type="body" color="colorTextStrong" textWrap>
            {labelText}
            {required && (
              <>
                <span aria-hidden="true">*</span>
                <Text visuallyHidden>{localized("Required")}</Text>
              </>
            )}
          </Text>
        </Box>
      )}
      {children}
    </Box>
  )
}

export const DownloadFileStep = ({
  labelText,
  stepNumber,
  downloadFile,
  onDownloaded,
  wrapperProps = {},
  required = true,
}) => {
  const [loading, setLoading] = useMountedState(false)

  const download = async () => {
    setLoading(true)
    try {
      const result = await downloadFile()
      onDownloaded?.(result)
    } catch (error) {
      reportErrorAndShowMessage(error, localizationKey("Download Failed"))
    } finally {
      setLoading(false)
    }
  }

  return (
    <ConfigStep {...{ wrapperProps, stepNumber, labelText, required }}>
      <Button labelToken={localizationKey("Download file")} Icon={DownloadIcon} onClick={download} disabled={loading} />
    </ConfigStep>
  )
}

export const UploadFileStep = ({
  stepNumber,
  name,
  labelText,
  onSelectFile,
  wrapperProps,
  errorTitleToken,
  errorDescriptionToken,
  accept,
  fileType = "text/document",
  noFileLabel,
}) => {
  const fileRef = useRef()
  const [selectedFile, setSelectedFile] = useState(null)

  const onChange = e => {
    setSelectedFile(e.target.files[0])
    onSelectFile?.(e)
  }

  const selectFileType = type => {
    return isNotEmpty(type) ? type : fileType
  }

  return (
    <ConfigStep {...{ stepNumber, labelText, wrapperProps, required: true }}>
      <HiddenInput {...{ name, onChange, accept }} ref={fileRef} type="file" placeholder={localized("Upload file")} />
      <Flex alignItems="center">
        <Button
          labelToken={localizationKey("Upload file")}
          onClick={() => fileRef.current.click()}
          Icon={ArrowUpToLineIcon}
        />
        {noFileLabel && !selectedFile && (
          <Box marginLeft={spacing[2]}>
            <Text type="body" color="colorTextStrong" as="p">
              {noFileLabel}
            </Text>
          </Box>
        )}
      </Flex>
      {selectedFile && (
        <Box marginTop={spacing[2]}>
          <FileAttachment
            file={{ name: selectedFile?.name, size: selectedFile?.size, type: selectFileType(selectedFile?.type) }}
          />
        </Box>
      )}
    </ConfigStep>
  )
}
