import styled from "@emotion/styled"
import { Text } from "@ninjaone/components"
import { spacing, typography } from "@ninjaone/tokens"

// TODO: (NJ-99147) move this component to a centralized folder/file since this is being used in js/includes/editors/Policy/PolicyEditor/tabs/mdm/ios/customPayload/DeletePayloadModal.js which is out of integrations/*
export const BoldText = ({ children }) => {
  return (
    <Text type="body" fontWeight={typography.fontWeight.semiBold} as="span" textWrap wordWrap="break-word">
      {children}
    </Text>
  )
}

const StyledUl = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: ${spacing[1]};
  padding-left: 0;
  margin-bottom: 0;

  > li {
    display: flex;
    flex-direction: column;
  }
`

export const TooltipKeyValueList = ({ fields }) => {
  return (
    <StyledUl>
      {fields.map(({ name, value, key }) => (
        <li key={key}>
          <Text type="bodyXs" fontWeight={typography.fontWeight.semiBold}>
            {name ?? "-"}
          </Text>
          <Text type="bodyXs">{value ?? "-"}</Text>
        </li>
      ))}
    </StyledUl>
  )
}
