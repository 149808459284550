import styled from "@emotion/styled"
import { Accordion, Button, Text } from "@ninjaone/components"
import { ExpiredIconSvg, NotExpiringSoonIconSvg } from "@ninjaone/icons"
import { spacing, typography } from "@ninjaone/tokens"
import { Flex } from "js/includes/components/Styled"
import { dateTimeTz, localizationKey, localized } from "js/includes/common/utils"
import { DataPanels, DataTitle } from "js/includes/configuration/apps/components/ConfigurationWidget"
import { MDMConnectionStatus } from "js/includes/configuration/integrations/mdm/constants"

export const StyledCard = styled(Flex)`
  background-color: ${({ theme }) => theme.colorBackgroundAccentNeutralWeakest};
  border-radius: ${spacing[1]};
  overflow: auto;
`

export const CertificateInfoCard = ({ connection, isADE = false, isVPP = false }) => {
  const isExpired = connection.status === MDMConnectionStatus.EXPIRED

  const getData = ({ isADE, isVPP }) => {
    const commonItems = [localizationKey("Status:"), localizationKey("Expiration date:")]
    // Note: status value is included by default (via `isExpired`)

    if (isADE) {
      return {
        fields: [...commonItems, localizationKey("Server name:")],
        values: [
          { text: dateTimeTz(connection.adeTokenExpirationDate), key: "expiration-date" },
          { text: connection.serverName, key: "server-name" },
        ],
      }
    }

    if (isVPP) {
      return {
        fields: [...commonItems, localizationKey("Tenant name:"), localizationKey("Location name:")],
        values: [
          { text: dateTimeTz(connection.expirationTime), key: "expiration-date" },
          { text: connection.tenantName, key: "tenant-name" },
          { text: connection.tokenLocation, key: "location-name" },
        ],
      }
    }

    // APNs
    return {
      fields: [...commonItems, localizationKey("Apple ID:"), localizationKey("Serial number:")],
      values: [
        { text: dateTimeTz(connection.expirationTime), key: "expiration-date" },
        { text: connection.appleId, key: "apple-id" },
        { text: connection.serialNumber, key: "serial-number" },
      ],
    }
  }

  const data = getData({ isADE, isVPP })

  return (
    <StyledCard flexDirection="column" gap={spacing[2]} padding={spacing[4]}>
      <Text
        token={localizationKey("Certificate")}
        type="body"
        color="colorTextStrong"
        fontWeight={typography.fontWeight.medium}
      />

      <DataPanels
        gap={spacing[1]}
        leftPanel={data.fields.map(token => (
          <DataTitle key={token} {...{ token }} />
        ))}
        rightPanel={
          <>
            <Flex alignItems="center">
              {isExpired ? <ExpiredIconSvg /> : <NotExpiringSoonIconSvg />}
              &nbsp;
              <Text color="colorTextStrong" type="body">
                {isExpired ? localized("Expired") : localized("Active")}
              </Text>
            </Flex>

            {data.values.map(({ text, key }) => (
              <Text {...{ key }} color="colorTextStrong" type="body">
                {text}
              </Text>
            ))}
          </>
        }
      />
    </StyledCard>
  )
}

export const CertificateInfoAccordion = ({
  connection,
  onOpenRenew,
  expanded = true,
  isADE = false,
  isVPP = false,
}) => {
  return (
    <Accordion
      items={[
        {
          id: "certificateInfo",
          titleToken: localizationKey("View current certificate"),
          rendererSubMenu: () => (
            <Flex flexDirection="column" gap={spacing[2]}>
              <CertificateInfoCard {...{ connection, isADE, isVPP }} />
              {onOpenRenew && (
                <Button type="tertiary" labelText={localized("Renew certificate")} onClick={onOpenRenew} />
              )}
            </Flex>
          ),
        },
      ]}
      {...(expanded && { defaultExpandedMenu: "certificateInfo" })}
      {...{ isADE }}
    />
  )
}
